/* ======================== */
/* Global Css=============== */
/* ======================== */

* {
  box-sizing: border-box;
}

#main-container {
  max-width: 1200px;
  margin: 30px auto 0;
}

body {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  background-color: #edf2f9;
  position: relative;
  top: 58px;
}

body,
.header,
.footer {
  padding: 20px 100px;
}

section {
  background-color: white;
  padding: 2rem !important;
  z-index: 0;
}

h1 {
  font-size: 3em;
  font-weight: bold;
  line-height: 52px;
}

h2 {
  font-size: 2.5em;
  font-weight: bold;
  line-height: 37px;
}

h3 {
  font-size: 2em;
  font-weight: bold;
  line-height: 33px;
}

h4 {
  font-size: 1.2em;
  font-weight: 700;
  line-height: 27px;
}

h5 {
  font-size: 1em;
  line-height: 19px;
  font-weight: bold;
}

p {
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
  font-style: normal;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  cursor: pointer;
}

hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(184, 183, 183, 0.5),
    rgba(184, 183, 183, 0.75),
    rgba(184, 183, 183, 0.5)
  );
  margin: 5px 0 20px;
  width: 100%;
}

.nav-link {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #004ed0;
}

.nav-link:hover {
  cursor: pointer;
  opacity: 0.7;
}

input {
  color: black !important;
}

.group form-input,
label {
  color: black !important;
}

.group select option {
  color: black !important;
}

/* ======================== */
/* Meida Queries =========*/
/* ======================== */

@media all and (max-width: 1300px) {
  body,
  .header,
  .footer {
    padding: 0 50px;
  }
}

@media all and (max-width: 1200px) {
  body,
  .header,
  .footer {
    padding: 0 10px;
  }
}

.full-width {
  width: 100% !important;
}
