body {
  background-attachment: rgba(206, 202, 202, 0.79); }

.error-boundry {
  position: relative;
  top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: #747272; }
  .error-boundry .icon {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 10px; }
  .error-boundry .title {
    font-size: 30px;
    font-weight: 600; }
  .error-boundry .desc {
    margin-top: 20px;
    font-size: 20px; }
