.popup-container {
    background: rgb(0, 0, 0, 0.6);
    position: absolute;
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalStyle {
  width: 500px;
  max-width: 100%;
  margin: 0 auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background-color: #eee !important;
  padding: 20px;
  border-radius: 8px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
};

.modal-content {
    margin: 10px auto !important;
}

.actions {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin: 1em auto 0;
}

.modalCloseBtnStyle {
    position: fixed;
    top: 0;
    right: 2px;
    font-size: 30px;
    color: #000;
    cursor: pointer;
};
 