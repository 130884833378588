.menu-dropdown {
    position: absolute;
    width: 240px;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 20px 30px 20px 10px;
    // border: 1px solid black;
    background-color: white;
    top: 50px;
    // right: 0px;
    z-index: -1;

    .menu-items {
      height: auto;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .menu-item {
        font-size: 16px;
        color: #000;
        text-transform: capitalize;
        padding: 1em;
        opacity: 0.55;

        &:hover {
            cursor: pointer;
            opacity: 1;
            color: #004ED0;
        }
        
      }
      .sub-menu-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        &:hover {
          transition: none !important;
          opacity: 1;
        }
      }
    }
  
    button {
      margin-top: 10px;
    }
}
  
@media all and (max-width: 500px) {
  .menu-dropdown {
    width:100%;
    position: absolute;
    top: 58px !important;
    left: 0;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 20px 30px 20px 10px;
    // border: 1px solid black;
    background-color: white;
    top: 50px;
    // right: 0px;
    z-index: -1;
  }
}