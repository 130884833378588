.custom-button {
  min-width: 100px;
  width: auto;
  height: 48px;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0.5px;
  padding: 0 32px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  flex: 1; }
  .custom-button:hover {
    cursor: pointer;
    opacity: 0.8; }
  .custom-button:focus {
    outline: none; }
  .custom-button.inverted {
    background: #fff !important;
    color: #2D2D2D;
    border-radius: 5px !important; }
    .custom-button.inverted:hover {
      cursor: pointer;
      background: #004ED0 !important;
      color: #fff;
      opacity: 1 !important; }
    .custom-button.inverted:focus {
      outline: none; }
  .custom-button.disabled-btn {
    border: 1px solid #999999;
    background: #cccccc !important;
    color: #666666; }
    .custom-button.disabled-btn:hover {
      cursor: none;
      background-color: #cccccc;
      color: #666666;
      opacity: 1 !important; }
    .custom-button.disabled-btn:focus {
      outline: none; }
  .custom-button.leads-only {
    padding: 0 15px;
    min-width: 40px;
    height: 53px;
    text-decoration: none;
    text-transform: uppercase;
    color: #ffffff;
    background: #004ed0;
    border-radius: 0px 5px 5px 0px;
    border: none;
    position: absolute;
    right: 0rem;
    outline: none;
    background: linear-gradient(to top, #004ED0, #93b6f1, 0.6); }
  .custom-button.left {
    position: relative;
    right: -10px;
    border-bottom-left-radius: 5px !important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: none !important;
    border-bottom-right-radius: none !important; }
    .custom-button.left:hover {
      cursor: pointer;
      background: #004ED0 !important;
      color: #fff;
      opacity: 1 !important; }
  .custom-button.right {
    position: relative;
    left: -10px;
    border-bottom-left-radius: none !important;
    border-top-left-radius: none !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important; }
    .custom-button.right:hover {
      cursor: pointer;
      background: #004ED0 !important;
      color: #fff;
      opacity: 1 !important; }
